import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPageScreen from "./Screens/LandingPageScreen";
import OtpPageScreen from "./Screens/OtpPageScreen";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsConditions from "./component/TermsConditions";
import TagManager from "react-gtm-module";
import AwarenessScreen from "./Screens/AwarenessScreen";
import { GenerateTokenService } from "./Services/SubscriptionServices";
import { IgenTokenApiResp } from "./@types/ApiResponse";

const App = () => {
  // states
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-M87FJDLJ",
    };
    TagManager.initialize(tagManagerArgs);
    GenerateToken();
  }, []);

  const GenerateToken = async () => {
    try {
      const response: IgenTokenApiResp = await GenerateTokenService();
      if (response?.status === 0) {
        setToken(response.response.token);
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/landing" />} />
        <Route path="/landing" element={<LandingPageScreen token={token} />} />
        <Route path="/pin" element={<OtpPageScreen token={token} />} />
        <Route path="/awareness" element={<AwarenessScreen token={token} />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
