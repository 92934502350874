export const domainName = "http://ufone.gplclub.mobi";

export const heUrl = `${domainName}/partners/api/v1/digitology/he-msisdn`;

export const navigationUrl =
  "http://ufone.gplclub.mobi/store/identify?utm_source=ads_digitology";

export const baseUrl = "https://ugplclub.ae/api/";

export const username = "86hAghLdsPhyuHA";
export const password = "1Qa7f1fdDssfrvjSUqh20";
