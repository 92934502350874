import React from "react";
import "../styles/Terms.css";

const TermsConditions = () => {
  return (
    <div className="jazz_discount_outer">
      <h2>Terms & Conditions</h2>

      <section
        id="page-center"
        style={{ background: "rgba(0,0,0,0.8)", padding: "10px" }}
      >
        <div className="page-content">
          <div id="tnc_content">
            <p>
              <strong>Terms of GPL Club Use</strong>
            </p>
            <p>
              These Terms and Conditions govern your use of the service at{" "}
              <a href="http://ufone.gplclub.mobi/">http://ufone.gplclub.mobi</a>{" "}
              according to the subscription scheme, which is provided to Ufone
              subscribers using the Ufone network (hereinafter, the "Service").
            </p>
            <p>
              <strong>Service Subscription and unsubscription:</strong>
            </p>
            <p>
              a. Confirm activation at{" "}
              <a href="http://ufone.gplclub.mobi/">http://ufone.gplclub.mobi</a>{" "}
              and other advertising links as promoted by GPL
            </p>
            <p>
              b. Subscribe to be charged a subscription fee which is mentioned
              in the subscription page. Upon subscription, user agrees to
              service recurring charges, auto-renewal until unsubscription &amp;
              discounted pricing from mobile balance.
            </p>
            <p>c. To cancel the service, please visit your Account.</p>
            <p>
              d. GPL service can also be canceled by sending SMS with the text
              “STOP GP” to 3080
            </p>
            <p>
              e. Service subscription &amp; unsubscription notification sms for
              services subscribed &amp; unsubscribed after 9pm, will be sent
              accordingly.
            </p>
            <p>
              f. Service renewal SMS notifications for subscribed services may
              be sent anytime during 24 hours.
            </p>
            <p>
              <strong>
                Conditions of downloading content when using the Service:
              </strong>
            </p>
            <p>
              Content can only be downloaded by Ufone customers. There are free
              tournaments and paid tournaments which are to be played with an
              entry fee in terms of coins.
            </p>
            <p>
              <strong>
                Conditions for providing information to Service users:
              </strong>
            </p>
            <p>
              During Service activation, the subscriber consents to receiving
              text messages for new services. Text messages are sent from Ufone
              which does not incur any charges.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsConditions;
